import React from 'react';
import thumbnail from '../assets/default_img.jpg';
import '../components/Video-form.css';
import { Navigate, useLocation,useNavigate  } from 'react-router-dom';
import { useState } from 'react';
import { BrowserRouter , Route,Routes} from 'react-router-dom'
import Navbar from '../components/Navbar'
import axios from 'axios';
import Dropzone from 'react-dropzone';
function MyPlayer (){
  const location = useLocation();
  const file = location.state.file[0];
  console.log(file);
  const video = location.state.file
  .map(file =>
   Object.assign( URL.createObjectURL(file)
    ));
  //file["path",video]; 
  console.log(file[0]);
  const [image, setimage] = useState()
  const [name, setName] = useState("") // set default to be file name?
  const [description, setDescription] = useState("")
  const [coverphoto, setCoverphoto] = useState(thumbnail);

  const [warmup, setWarmup] = useState(0);
  const [mainflow, setMainflow] = useState(0);
  const [cooldown, setCooldown] = useState(0);

  const [upperbody, setUpperbody] = useState(0);
  const [lowerbody, setLowerbody] = useState(0);
  const [posteriorchain, setPosteriorchain] = useState(0);
  const [anteriorchain, setAnteriorchain] = useState(0);
  const [core, setCore] = useState(0);

  const [abdominals, setAbdominals] = useState(0);
  const [glutes, setGlutes] = useState(0);
  const [arms, setArms] = useState(0);
  const [biceps, setBiceps] = useState(0);
  const [triceps, setTriceps] = useState(0);
  const [shoulders, setShoulders] = useState(0);
  const [traps, setTraps] = useState(0);
  const [back, setBack] = useState(0);
  const [lats, setLats] = useState(0);
  const [chest, setChest] = useState(0);
  const [obliques, setObliques] = useState(0);
  const [legs, setLegs] = useState(0);
  const [calves, setCalves] = useState(0);
  const [hamstrings, setHamstrings] = useState(0);
  const [quads, setQuads] = useState(0);
  const [hips, setHips] = useState(0);
  const [innerthigh, setInnerthigh] = useState(0);

  const [prone, setProne] = useState(0);
  const [supine, setSupine] = useState(0);
  const [frontseated, setFrontseated] = useState(0);
  const [sideseated, setSideseated] = useState(0);
  const [sidekneeling, setSidekneeling] = useState(0);
  const [frontkneeling, setFrontkneeling] = useState(0);
  const [standingwork, setStandingwork] = useState(0);
  const [sidelying,setSidelying] = useState(0);
  const [plank, setPlank] = useState(0);
  const [sideplank,setSideplank] = useState(0);
  const [fourpointkneeling, setFourpointkneeling] = useState(0);
  const [lunge,setLunge] = useState(0);
  const [bridge, setBridge] = useState(0);


  const [flexion, setFlexion] = useState(0);
  const [lateralflexion, setLateralflexion] = useState(0);
  const [extension, setExtension] = useState(0);
  const [rotation, setRotation] = useState(0);
  const [abduction, setAbduction] = useState(0);
  const [adduction, setAdduction] = useState(0);

  const [longbox, setLongbox] = useState(0);
  const [shortbox, setShortbox] = useState(0);
  const [softpilatesball, setSoftpilatesball] = useState(0);
  const [foamroller, setFoamroller] = useState(0);
  const [swissball, setSwissball] = useState(0);
  const [magiccircle, setMagiccircle] = useState(0);
  const [weights, setWeights] = useState(0);
  const [longresistanceband, setLongresistanceband] = useState(0);
  const [loopresistanceband, setLoopresistanceband] = useState(0);
  const [pole, setPole] = useState(0);
  const [gliders, setGliders] = useState(0);
  const [ankleweights, setAnkleweights] = useState(0);
  const [jumpboardprop, setJumpboardprop] = useState(0);
  const [pilatesarcprop, setPilatesarcprop] = useState(0);
  const [floorbox, setFloorbox] = useState(0);
  const [bodyweight, setBodyweight] = useState(0);
  const [boxoverrails, setBoxoverrails] = useState(0);
  const [platformbox, setPlatformbox] = useState(0);


  const [beginner, setBeginner] = useState(0);
  const [intermediate, setIntermediate] = useState(0);
  const [advanced, setAdvanced] = useState(0);

  const [classicalexcercises, setClassicalexcercies] = useState(0);
  const [prepostnatal, setPrepostnatal] = useState(0);
  const [dynamic,setDynamic] = useState(0);
  const [jumpboard,setJumpboard] = useState(0);
  const [strenght,setStrength] = useState(0);
  const [stretch,setStretch] = useState(0);


  const [mat, setMat] = useState(0);
  const [reformer, setReformer] = useState(0);
  const [jumpboardequip,setJumpboardequip] = useState(0);
  const [chair, setChair] = useState(0);
  const [tower, setTower] = useState(0);
  const [pilatesarcequip, setPilatesarcequip] = useState(0);

  const handlename = (event) => {
    setName(event.target.value);
  };
  const handledesc = (event) => {
    setDescription(event.target.value);
  };

  const handleUpload = (acceptedFiles) => {
    if (acceptedFiles.length == 1){
      console.log(acceptedFiles);
      if (acceptedFiles[0].name.match(/\.(jpg|png|jpeg)$/))
        { setimage(acceptedFiles[0]);
          setCoverphoto(acceptedFiles.map(file =>
          Object.assign( URL.createObjectURL(file)
          )))}
      else{
        alert('incorrect file type')
      }}
    else{
      alert('please do not select more than one file as thumbnail')
    }  
      };

  const handleFilter = (data) => {

    if (data=="warmup"){
      if (warmup == 1)
        {setWarmup(0)}
      else{setWarmup(1)};
    }
      if (data=="cooldown"){
        if (cooldown == 1)
          {setCooldown(0)}
        else{setCooldown(1)};
      
        }
      if (data=="mainflow"){
        if (mainflow == 1)
        {setMainflow(0)}
        else{setMainflow(1)};
      }
      if (data=="upperbody"){
        if (upperbody == 1)
        {setUpperbody(0)}
        else{setUpperbody(1)};
      }
      if (data=="lowerbody"){
        if (lowerbody == 1)
        {setLowerbody(0)}
        else{setLowerbody(1)};
      }
      if (data=="abdominals"){
        if (abdominals == 1)
        {setAbdominals(0)}
        else{setAbdominals(1)};
      }
      if (data=="glutes"){
        if (glutes == 1)
        {setGlutes(0)}
        else{setGlutes(1)};
      }
      if (data=="arms"){
        if (arms == 1)
        {setArms(0)}
        else{setArms(1)};
      }
      if (data=="biceps"){
        if (biceps == 1)
        {setBiceps(0)}
        else{setBiceps(1)};
      }
      if (data=="triceps"){
        if (triceps == 1)
        {setTriceps(0)}
        else{setTriceps(1)};
      }
      if (data=="shoulders"){
        if ( shoulders== 1)
        {setShoulders(0)}
        else{setShoulders(1)};
      }
      if (data=="traps"){
        if ( traps == 1)
        {setTraps(0)}
        else{setTraps(1)};
      }
      if (data=="back"){
        if ( back == 1)
        {setBack(0)}
        else{setBack(1)};
      }
      if (data=="lats"){
        if ( lats == 1)
        {setLats(0)}
        else{setLats(1)};
      }
      if (data=="chest"){
        if ( chest == 1)
        {setChest(0)}
        else{setChest(1)};
      }
      if (data=="obliques"){
        if ( obliques == 1)
        {setObliques(0)}
        else{setObliques(1)};
      }
      if (data=="legs"){
        if ( legs == 1)
        {setLegs(0)}
        else{setLegs(1)};
      }
      if (data=="glutes"){
        if ( glutes == 1)
        {setGlutes(0)}
        else{setGlutes(1)};
      }
      if (data=="calves"){
        if (calves== 1)
        {setCalves(0)}
        else{setCalves(1)};
      }
      if (data=="hamstrings"){
        if ( hamstrings== 1)
        {setHamstrings(0)}
        else{setHamstrings(1)};
      }
      if (data=="quads"){
        if ( quads == 1)
        {setQuads(0)}
        else{setQuads(1)};
      }
      if (data=="hips"){
        if (hips == 1)
        {setHips(0)}
        else{setHips(1)};
      }
      if (data=="posteriorchain"){
        if (posteriorchain == 1)
        {setPosteriorchain(0)}
        else{setPosteriorchain(1)};
      }
      if (data=="anteriorchain"){
        if (anteriorchain == 1)
        {setAnteriorchain(0)}
        else{setAnteriorchain(1)};
      }
      if (data=="core"){
        if (core == 1)
        {setCore(0)}
        else{setCore(1)};
      }
      if (data=="prone"){
        if (prone == 1)
        {setProne(0)}
        else{setProne(1)};
      }
      if (data=="supine"){
        if (supine == 1)
        {setSupine(0)}
        else{setSupine(1)};
      }
      if (data=="sideseated"){
        if (sideseated== 1)
        {setSideseated(0)}
        else{setSideseated(1)};
      }
      if (data=="frontseated"){
        if (frontseated== 1)
        {setFrontseated(0)}
        else{setFrontseated(1)};
      }
      if (data=="frontkneeling"){
        if (frontkneeling == 1)
        {setFrontkneeling(0)}
        else{setFrontkneeling(1)};
      }
      if (data=="sidekneeling"){
        if (sidekneeling == 1)
        {setSidekneeling(0)}
        else{setSidekneeling(1)};
      }
      if (data=="standingwork"){
        if (standingwork == 1)
        {setStandingwork(0)}
        else{setStandingwork(1)};
      }
      if (data=="sidelying"){
        if ( sidelying == 1)
        {setSidelying(0)}
        else{setSidelying(1)};
      }
      if (data=="plank"){
        if ( plank == 1)
        {setPlank(0)}
        else{setPlank(1)};
      }
      if (data=="sideplank"){
        if ( sideplank == 1)
        {setSideplank(0)}
        else{setSideplank(1)};
      }
      if (data=="flexion"){
        if (flexion == 1)
        {setFlexion(0)}
        else{setFlexion(1)};
      }
      if (data=="lateralflexion"){
        if (lateralflexion == 1)
        {setLateralflexion(0)}
        else{setLateralflexion(1)};
      }
      if (data=="extension"){
        if (extension == 1)
        {setExtension(0)}
        else{setExtension(1)};
      }
      if (data=="rotation"){
        if (rotation == 1)
        {setRotation(0)}
        else{setRotation(1)};
      }
      if (data=="abduction"){
        if (abduction == 1)
        {setAbduction(0)}
        else{setAbduction(1)};
      }
      if (data=="adduction"){
        if (adduction == 1)
        {setAdduction(0)}
        else{setAdduction(1)};
      }
      if (data=="longbox"){
        if (longbox == 1)
        {setLongbox(0)}
        else{setLongbox(1)};
      }
      if (data=="shortbox"){
        if (shortbox == 1)
        {setShortbox(0)}
        else{setShortbox(1)};
      }
      if (data=="softpilatesball"){
        if (softpilatesball == 1)
        {setSoftpilatesball(0)}
        else{setSoftpilatesball(1)};
      }
      if (data=="swissball"){
        if (swissball== 1)
        {setSwissball(0)}
        else{setSwissball(1)};
      }
      if (data=="magiccircle"){
        if (magiccircle == 1)
        {setMagiccircle(0)}
        else{setMagiccircle(1)};
      }
      if (data=="weights"){
        if (weights == 1)
        {setWeights(0)}
        else{setWeights(1)};
      }
      if (data=="longresistanceband"){
        if (longresistanceband == 1)
        {setLongresistanceband(0)}
        else{setLongresistanceband(1)};
      }
      if (data=="loopresistanceband"){
        if (loopresistanceband== 1)
        {setLoopresistanceband(0)}
        else{setLoopresistanceband(1)};
      }
      if (data=="pole"){
        if (pole == 1)
        {setPole(0)}
        else{setPole(1)};
      }
      if (data=="gliders"){
        if (gliders == 1)
        {setGliders(0)}
        else{setGliders(1)};
      }
      if (data=="pilatesarcprop"){
        if (pilatesarcprop == 1)
        {setPilatesarcprop(0)}
        else{setPilatesarcprop(1)};
      }
      if (data=="ankleweights"){
        if (ankleweights== 1)
        {setAnkleweights(0)}
        else{setAnkleweights(1)};
      }
      if (data=="jumpboardprop"){
        if (jumpboardprop == 1)
        {setJumpboardprop(0)}
        else{setJumpboardprop(1)};
      }
      if (data=="floorbox"){
        if (  floorbox == 1)
        {setFloorbox(0)}
        else{setFloorbox(1)};
      }
      if (data=="bodyweights"){
        if ( bodyweight == 1)
        {setBodyweight(0)}
        else{setBodyweight(1)};
      }
      if (data=="beginner"){
        if (beginner == 1)
        {setBeginner(0)}
        else{setBeginner(1)};
      }
      if (data=="intermediate"){
        if (intermediate == 1)
        {setIntermediate(0)}
        else{setIntermediate(1)};
      }
      if (data=="advanced"){
        if (advanced == 1)
        {setAdvanced(0)}
        else{setAdvanced(1)};
      }
      if (data=="classicalexcercises"){
        if (classicalexcercises == 1)
        {setClassicalexcercies(0)}
        else{setClassicalexcercies(1)};
      }
      if (data=="dynamicpilates"){
        if ( dynamic == 1)
        {setDynamic(0)}
        else{setDynamic(1)};
      }
      if (data=="jumpboard"){
        if (jumpboard == 1)
        {setJumpboard(0)}
        else{setJumpboard(1)};
      }
      if (data=="strength"){
        if (strenght == 1)
        {setStrength(0)}
        else{setStrength(1)};
      }
      if (data=="stretch"){
        if ( stretch == 1)
        {setStretch(0)}
        else{setStretch(1)};
      }
      if (data=="prepostnatal"){
        if (prepostnatal == 1)
        {setPrepostnatal(0)}
        else{setPrepostnatal(1)};
      }
      if (data=="mat"){
        if (mat == 1)
        {setMat(0)}
        else{setMat(1)};
      }
      if (data=="reformer"){
        if (reformer == 1)
        {setReformer(0)}
        else{setReformer(1)};
      }
      if (data=="chair"){
        if (chair == 1)
        {setChair(0)}
        else{setChair(1)};
      }
      if (data=="tower"){
        if (tower == 1)
        {setTower(0)}
        else{setTower(1)};
      }
      if (data=="jumpboardequip"){
        if (jumpboardequip == 1)
        {setJumpboardequip(0)}
        else{setJumpboardequip(1)};
      }
      if (data=="pilatesarcequip"){
        if ( pilatesarcequip == 1)
        {setPilatesarcequip(0)}
        else{setPilatesarcequip(1)};
      }
      if (data=="foamroller"){
        if ( foamroller == 1)
        {setFoamroller(0)}
        else{setFoamroller(1)};
      }
      if (data=="fourpointkneeling"){
        if (fourpointkneeling == 1)
        {setFourpointkneeling(0)}
        else{setFourpointkneeling(1)};
      }
      if (data=="bridge"){
        if (bridge == 1)
        {setBridge(0)}
        else{setBridge(1)};
      }if (data=="lunge"){
        if ( lunge == 1)
        {setLunge(0)}
        else{setLunge(1)};
      }if (data=="innerthigh"){
        if (innerthigh == 1)
        {setInnerthigh(0)}
        else{setInnerthigh(1)};
      }if (data=="platformbox"){
        if (platformbox == 1)
        {setPlatformbox(0)}
        else{setPlatformbox(1)};
      }if (data=="boxoverrails"){
        if (boxoverrails == 1)
        {setBoxoverrails(0)}
        else{setBoxoverrails(1)};
      }
      
    }
  
    const classplacement = warmup.toString() + mainflow.toString() + cooldown.toString() 
    const targetarea =  upperbody.toString() + lowerbody.toString() + posteriorchain.toString() + anteriorchain.toString() + core.toString() 
    const musclegroups = arms.toString() + biceps.toString() + triceps.toString() + shoulders.toString() + traps.toString() + back.toString() + lats.toString() + chest.toString() + abdominals.toString() + obliques.toString() + legs.toString() + glutes.toString() + calves.toString() + hamstrings.toString() + quads.toString() + hips.toString() + innerthigh.toString()
    const bodypositions = prone.toString() + supine.toString() + standingwork.toString() +frontseated.toString() + sideseated.toString() + frontkneeling.toString() + sidekneeling.toString() + sidelying.toString() + plank.toString() + sideplank.toString() + fourpointkneeling.toString() + lunge.toString() + bridge.toString()
    const movementdirection = flexion.toString() + lateralflexion.toString() +extension.toString() + rotation.toString() +abduction.toString() + adduction.toString() 
    const props = longbox.toString() + shortbox.toString() + softpilatesball.toString() + swissball.toString() + magiccircle.toString() + weights.toString() + longresistanceband.toString() + loopresistanceband.toString() + pole.toString() + gliders.toString() + ankleweights.toString() + pilatesarcprop.toString() + jumpboardprop.toString() + foamroller.toString() + floorbox.toString() + bodyweight.toString() +  boxoverrails.toString() + platformbox.toString()
    const level = beginner.toString() + intermediate.toString() + advanced.toString()
    const specialitycategories = classicalexcercises.toString() + dynamic.toString() + jumpboard.toString() + strenght.toString() +stretch.toString() + prepostnatal.toString() 
    const equipment = mat.toString() + reformer.toString() + chair.toString() + tower.toString() + jumpboardequip.toString() + pilatesarcequip.toString() 


const navigate = useNavigate();
  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("file",file);
    formData.append("name",name);
    formData.append("description",description);
    formData.append("image", image);
    formData.append("placement",classplacement.toString());
    formData.append("target",targetarea.toString());
    formData.append("muscle",musclegroups.toString());
    formData.append("position",bodypositions.toString());
    formData.append("directions",movementdirection.toString());
    formData.append("props",props.toString());
    formData.append("level",level.toString());
    formData.append("specialty",specialitycategories.toString());
    formData.append("equipment",equipment.toString());
    if (name==""){
      alert("Please insert file name");
      return;
    }
    if (!image){
      alert("Please upload a thumbnail for you video");
      return;
    }
    
    axios.post("https://springapi-xsvy.onrender.com/videoupload",formData)
      .then((response) => {
          alert('Upload succesful');
          
          navigate("/videos");
        })
        .catch((error) => {
          console.error(error);
          alert('Upload failed')
        })

  }
  return(
    <>
    <Navbar/>
    <div className = "app1">
      <form className= "form1">
        <br/>
      <div className = "title1">
        <h3 className='edithead'>Title</h3>
        <br/>
        <textarea onChange={handlename} rows={1} cols={(35)} style={{borderRadius: '5px'}} />
      </div>
      <br/>
      <br/>
      <div className = "description1">
        <h3 className='editdes'>Description</h3>
        <br/>
        <textarea onChange={handledesc} rows={5} cols={(35)} style={{borderRadius: '5px'}} />
      </div>
      <br/>
      <div className = "filters">
      <h3>Filters</h3>
      <div className= "sub-filter">
        <h5>Class Placement</h5>
        <input className = "checkbox" onChange={()=> handleFilter("warmup")}type= "checkbox"/>
        <label className="category">Warm-up</label>
        <input className = "checkbox" onChange={()=> handleFilter("mainflow")}type= "checkbox"/>
        <label className="category">Main Flow</label>
        <input className = "checkbox" onChange={()=> handleFilter("cooldown")}type= "checkbox"/>
        <label className="category">Cool Down</label>
      </div>
    
      <div className= "sub-filter">
        <h5>Target Area</h5>
        <input className = "checkbox" onChange={()=> handleFilter("upperbody")}type= "checkbox"/>
        <label className="category">Upper Body</label>
        <input className = "checkbox" onChange={()=> handleFilter("lowerbody")} type= "checkbox"/>
        <label className="category">Lower body</label>
        <input className = "checkbox"onChange={()=> handleFilter("posteriorchain")} type= "checkbox"/>
        <label className="category">Posterior Chain</label>
        <input className = "checkbox" onChange={()=> handleFilter("anteriorchain")}type= "checkbox"/>
        <label className="category">Anterior Chain</label>
        <input className = "checkbox" onChange={()=> handleFilter("core")}type= "checkbox"/>
        <label>Core</label>
        
      </div>
      <div className= "sub-filter">
        <h5>Muscle Groups</h5>   
        <input className = "checkbox" onChange={()=> handleFilter("arms")}type= "checkbox"/>
        <label className="category">Arms</label>
        <input className = "checkbox" onChange={()=> handleFilter("biceps")}type= "checkbox"/>
        <label className="category">Biceps</label>
        <input className = "checkbox" onChange={()=> handleFilter("triceps")}type= "checkbox"/>
        <label className="category">Triceps</label>
        <input className = "checkbox" onChange={()=> handleFilter("shoulders")}type= "checkbox"/>
        <label className="category">Shoulders</label>
        <input className = "checkbox" onChange={()=> handleFilter("traps")}type= "checkbox"/>
        <label className="category">Traps</label>
        <input className = "checkbox" onChange={()=> handleFilter("back")}type= "checkbox"/>
        <label className="category">Back</label>
        <input className = "checkbox" onChange={()=> handleFilter("lats")}type= "checkbox"/>
        <label className="category">Lats</label>
        <input className = "checkbox" onChange={()=> handleFilter("chest")}type= "checkbox"/>
        <label className="category">Chest</label>
        <input className = "checkbox" onChange={()=> handleFilter("abdominals")}type= "checkbox"/>
        <label className="category">Abdominals</label>
        <input className = "checkbox" onChange={()=> handleFilter("obliques")}type= "checkbox"/>
        <label className="category">Obliques</label>
        <input className = "checkbox" onChange={()=> handleFilter("legs")}type= "checkbox"/>
        <label className="category">Legs</label>
        <input className = "checkbox" onChange={()=> handleFilter("glutes")}type= "checkbox"/>
        <label className="category">Glutes</label>
        <input className = "checkbox" onChange={()=> handleFilter("calves")}type= "checkbox"/>
        <label className="category">Calves</label>
        <input className = "checkbox" onChange={()=> handleFilter("hamstrings")}type= "checkbox"/>
        <label className="category">Hamstrings</label>
        <input className = "checkbox" onChange={()=> handleFilter("quads")}type= "checkbox"/>
        <label className="category">Quads</label>
        <input className = "checkbox" onChange={()=> handleFilter("hips")}type= "checkbox"/>
        <label className="category">Hips</label>
        <input className = "checkbox" onChange={()=> handleFilter("innerthigh")}type= "checkbox" />
        <label className="category">Inner Thigh</label>
      </div>
      <div className= "sub-filter">
        <h5>Body positions</h5>
        <input className = "checkbox" onChange={()=> handleFilter("prone")}type= "checkbox"/>
        <label className="category">Prone</label>
        <input className = "checkbox" onChange={()=> handleFilter("supine")}type= "checkbox"/>
        <label className="category">Supine</label>
        <input className = "checkbox" onChange={()=> handleFilter("standingwork")}type= "checkbox"/>
        <label className="category">Standing </label>
        <input className = "checkbox" onChange={()=> handleFilter("frontseated")}type= "checkbox"/>
        <label className="category">Front Seated</label>
        <input className = "checkbox" onChange={()=> handleFilter("sideseated")}type= "checkbox"/>
        <label className="category">Side Seated</label>
        <input className = "checkbox" onChange={()=> handleFilter("frontkneeling")}type= "checkbox"/>
        <label className="category">Front Kneeling</label>
        <input className = "checkbox" onChange={()=> handleFilter("sidekneeling")}type= "checkbox"/>
        <label className="category">Side Kneeling </label>
        <input className = "checkbox" onChange={()=> handleFilter("sidelying")}type= "checkbox"/>
        <label className="category">Side Lying</label>
        <input className = "checkbox" onChange={()=> handleFilter("plank")}type= "checkbox"/>
        <label className="category">Plank</label>
        <input className = "checkbox" onChange={()=> handleFilter("sideplank")}type= "checkbox"/>
        <label className="category">Side Plank</label>
        <input className = "checkbox" onChange={()=> handleFilter("fourpointkneeling")}type= "checkbox"/>
        <label className="category">Four Point Kneeling</label>
        <input className = "checkbox" onChange={()=> handleFilter("lunge")}type= "checkbox"/>
        <label className="category">Lunge</label>
        <input className = "checkbox" onChange={()=> handleFilter("bridge")}type= "checkbox" />
        <label className="category">Bridge</label>
      </div>
      <div ClassName="subfilter">
        <h5>Movement directions</h5>
        <input className = "checkbox" onChange={()=> handleFilter("flexion")}type= "checkbox"/>
        <label className="category">Flexion</label>
        <input className = "checkbox" onChange={()=> handleFilter("lateralflexion")}type= "checkbox"/>
        <label className="category">Lateral Flexion</label>
        <input className = "checkbox" onChange={()=> handleFilter("extension")} type= "checkbox"/>
        <label className="category">Extension</label>
        <input className = "checkbox" onChange={()=> handleFilter("rotation")}type= "checkbox"/>
        <label className="category">Rotation</label>
        <input className = "checkbox" onChange={()=> handleFilter("abduction")}type= "checkbox"/>
        <label className="category">Abduction</label>
        <input className = "checkbox" onChange={()=> handleFilter("adduction")}type= "checkbox"/>
        <label className="category">Adduction</label>
      </div>
      <div ClassName="subfilter">
        <h5>Props</h5>
        <input className = "checkbox" onChange={()=> handleFilter("longbox")}type= "checkbox"/>
        <label className="category">Long Box</label>
        <input className = "checkbox" onChange={()=> handleFilter("shortbox")} type= "checkbox"/>
        <label className="category">Short Box</label>
        <input className = "checkbox" onChange={()=> handleFilter("softpilatesball")}type= "checkbox"/>
        <label className="category">Soft Pilates Ball</label>
        <input className = "checkbox" onChange={()=> handleFilter("swissball")}type= "checkbox"/>
        <label className="category">Swiss Ball</label>
        <input className = "checkbox" onChange={()=> handleFilter("magiccircle")}type= "checkbox"/>
        <label className="category">Magic Circle</label>
        <input className = "checkbox" onChange={()=> handleFilter("weights")}type= "checkbox"/>
        <label className="category">Weights</label>
        <input className = "checkbox" onChange={()=> handleFilter("longresistanceband")}type= "checkbox"/>
        <label className="category">Long Resistance Band</label>
        <input className = "checkbox" onChange={()=> handleFilter("loopresistanceband")}type= "checkbox"/>
        <label className="category">Loop Resistance Band</label>
        <input className = "checkbox" onChange={()=> handleFilter("pole")}type= "checkbox"/>
        <label className="category">Pole</label>
        <input className = "checkbox" onChange={()=> handleFilter("gliders")}type= "checkbox"/>
        <label className="category">Gliders</label>
        <input className = "checkbox" onChange={()=> handleFilter("ankleweights")}type= "checkbox"/>
        <label className="category">Ankle Weights</label>
        <input className = "checkbox" onChange={()=> handleFilter("pilatesarcprop")}type= "checkbox"/>
        <label className="category">Pilates Arc</label>
        <input className = "checkbox" onChange={()=> handleFilter("jumpboardprop")}type= "checkbox"/>
        <label className="category">Jumpboard</label>
        <input className = "checkbox" onChange={()=> handleFilter("foamroller")}type= "checkbox"/>
        <label className="category">Foam Roller</label>
        <input className = "checkbox" onChange={()=> handleFilter("floorbox")}type= "checkbox"/>
        <label className="category">Side Car Box/ Floor Box</label>
        <input className = "checkbox" onChange={()=> handleFilter("bodyweights")}type= "checkbox"/>
        <label className="category">No Props/ Bodyweight</label>
        <input className = "checkbox" onChange={()=> handleFilter("boxoverrails")}type= "checkbox" />
        <label className="category">Box Over Rails</label>
        <input className = "checkbox" onChange={()=> handleFilter("platformbox")}type= "checkbox" />
        <label className="category">Platform Box</label>
      </div>
      <div className= "sub-filter">
        <h5>Level</h5>
        <input className = "checkbox" onChange={()=> handleFilter("beginner")}type= "checkbox"/>
        <label className="category">Beginner/ All Levels</label>
        <input className = "checkbox" onChange={()=> handleFilter("intermediate")}type= "checkbox"/>
        <label className="category">Intermediate/ Advanced</label>
      
      </div>
      <div className= "sub-filter">
        <h5>Speciality Categories</h5>
        <input className = "checkbox" onChange={()=> handleFilter("classicalexcercises")}type= "checkbox"/>
        <label className="category">Classical Excercises</label>
        <input className = "checkbox" onChange={()=> handleFilter("dynamicpilates")}type= "checkbox"/>
        <label className="category">Dynamic Pilates</label>
        <input className = "checkbox" onChange={()=> handleFilter("jumpboard")}type= "checkbox"/>
        <label className="category">Jumpboard </label>
        <input className = "checkbox" onChange={()=> handleFilter("strength")}type= "checkbox"/>
        <label className="category">Strength</label>
        <input className = "checkbox" onChange={()=> handleFilter("stretch")}type= "checkbox"/>
        <label className="category">Stretch </label>
        <input className = "checkbox" onChange={()=> handleFilter("prepostnatal")}type= "checkbox"/>
        <label className="category" >Pre/Post Natal</label>
      </div>
      <div className= "sub-filter">
        <h5>Equipment</h5>
        <input className = "checkbox" onChange={()=> handleFilter("mat")}type= "checkbox"/>
        <label className="category">Mat</label>
        <input className = "checkbox" onChange={()=> handleFilter("reformer")}type= "checkbox"/>
        <label className="category">Reformer</label>
        <input className = "checkbox" onChange={()=> handleFilter("chair")}type= "checkbox"/>
        <label className="category">Chair</label>
        <input className = "checkbox" onChange={()=> handleFilter("tower")}type= "checkbox"/>
        <label className="category">Tower</label>
        <input className = "checkbox" onChange={()=> handleFilter("jumpboardequip")}type= "checkbox"/>
        <label className="category">Jumpboard </label>
        <input className = "checkbox" onChange={()=> handleFilter("pilatesarcequip")}type= "checkbox"/>
        <label className="category">Pilates Arc</label>
      </div>
      </div>
      
    
      </form>
      
      <div className= "video-player">
      <video controls width = "750px" height ="600px" poster={coverphoto} >
        <source src={video} />
      </video>
     
      <br/>
      <br/>
      <div className="main-container">
        <Dropzone className="drag-drop" onDrop={handleUpload}>
          
          {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
           
  
            return (
              <div
                {...getRootProps({
                  className: `dropzone `,
                })}
              >
                <input {...getInputProps()} />
                <p>Drag'n'drop images, or click to select a thumbnail</p>
                <br/>
                <button className="button">Upload</button>
              </div>
            );
          }}
          
        </Dropzone>
        <p align ="left">
      <button onClick={handleSubmit}className="dragbutton">Save</button>
      </p>
      </div>
 
      </div>
    </div>
      
  
    </>
  );
}

export default MyPlayer;
